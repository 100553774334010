<template>
  <div>
    <div class="container" style="margin-bottom:200px; overflow-y: hidden;">
      <div class="mt-1" style="text-align:center"><h5 style="padding-top:3px;">Tasks Report</h5></div>
      <div class="mt-1" v-if="offices.length>0"><h6 style="padding-top:3px;"><strong>Offices:</strong> {{offices.map(item=>{return item.name}).join(", ")}}</h6></div>
      <div class="mt-1" v-if="users.length>0"><h6 style="padding-top:3px;"><strong>Users:</strong> {{users.map(item=>{return item.name}).join(", ")}}</h6></div>
      <div class="mt-1" v-if="clients.length>0"><h6 style="padding-top:3px;"><strong>Clients:</strong> {{clients.map(item=>{return item.name}).join(", ")}}</h6></div>
      <div class="mt-1" v-if="categories.length>0"><h6 style="padding-top:3px;"><strong>Categories:</strong> {{categories.join(", ")}}</h6></div>
      <div class="mt-1" v-if="status.length>0"><h6 style="padding-top:3px;"><strong>Status:</strong> {{status.join(", ")}}</h6></div>
      <div class="mt-1" v-if="date[0]!=null && date[1]!=null"><h6 style="padding-top:3px;"><strong>Date:</strong> {{date[0] | moment("MM-DD-YYYY")}} - {{date[1] | moment("MM-DD-YYYY")}}</h6></div>
      <div class="row">
        <div class="col-12 bg-light border border-dark border-padding" style="margin-bottom:3px"> 

          <table class="table-sm table table-border m-0 pt-1">
            <thead>
              <tr>
                <th scope="col">Client</th>
                <th scope="col">Assigned To</th>
                <th scope="col">Date</th>
                <th scope="col">Due Date</th>
                <th scope="col">Completed</th>
                <th scope="col">Status</th>
              </tr>
            </thead>
            <tbody>
              <tr v-bind:key="task.id" v-for="task in tableData">
                <td class="bt" style="font-weight: bold; color:#0e5499">{{task.corporate.name}}</td>
                <td class="bt" style="font-weight: bold; color:#0e5499">{{task.users.name}}</td>
                <td class="bt" style="font-weight: bold; color:#0e5499">{{ task.created_at | moment("MM-DD-YYYY") }}</td>
                <td class="bt" style="font-weight: bold; color:#0e5499">{{ task.duedate | moment("MM-DD-YYYY") }}</td>
                <td class="bt" style="font-weight: bold; color:#0e5499">{{ task.completionDate | moment("MM-DD-YYYY") }}</td>
                <td class="bt" style="font-weight: bold; color:#0e5499">
                  <i v-if="task.status==='Done'" class="fal fa-check-circle text-success"></i>
                  <i v-else class="fal fa-ban text-danger"></i>
                  {{task.status}}
                </td>
              </tr>
            </tbody>
          </table>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import officeUser from "@/services/api/officeUser";
import note from "@/services/api/note";

export default {
  data() {
    return {
      data:[],    
      tableData: [],
      offices: [],
      users: [],
      clients: [],
      categories: [],
      status: [],
      date: [null,null]
    };
  },
  mounted() {
    this.offices=JSON.parse(this.$route.query.offices);
    this.users=JSON.parse(this.$route.query.users);
    this.clients=JSON.parse(this.$route.query.clients);
    this.categories=JSON.parse(this.$route.query.categories);
    this.status=JSON.parse(this.$route.query.status);
    this.date=JSON.parse(this.$route.query.date);
    switch (this.$store.getters.discriminator) {
      case "administrator":
        this.discriminator = "administrator";
        note.getTasks().then(response => {
            this.data = Object.assign([], response);
            this.filterData();
          });
        break;
      case "office":
      case "employee":
        officeUser.getOffice(this.$store.getters.id).then(response => {
            let idOffice=response[0].office.id;
            note.getTasksOffice(idOffice).then(response => {
              this.data = Object.assign([], response);
              this.filterData();
            });
        });
        break;
    }
    this.download();
  },
  methods: {
    download() {
      this.fullscreenLoading = false;
      setTimeout(() => {
        this.$nextTick(() => {
          window.print();
        });
      }, 1000);
    },
    filterData(){

      let idOffices = this.offices.map(office=>office.id);
      let idUsers = this.users.map(user=>user.id);
      let clients = this.clients.map(client=>client.id);
      let categories = this.categories;
      let status = this.status;

      this.tableData = Object.assign([], this.data);

      if(idOffices.length>0 && idOffices.length!==this.lengthOfficeFilter){
        this.lengthOfficeFilter=idOffices.length;
        this.tableData=this.tableData.filter(item=>idOffices.indexOf(item.officeId)!=-1);
      }
      if(idOffices.length==0){
        this.lengthOfficeFilter=0;
        this.clientFilter=[];
        this.clients=[];
      }

      if(clients.length>0){
        this.tableData=this.tableData.filter(item=>clients.indexOf(item.corporate.id)!=-1);
      }

      if(idUsers.length>0){
        this.tableData=this.tableData.filter(item=>idUsers.indexOf(item.users.id)!=-1);
      }

      if(categories.length>0){
        this.tableData=this.tableData.filter(item=>categories.indexOf(item.category)!=-1);
      }

      if(status.length>0){
        this.tableData=this.tableData.filter(item=>status.indexOf(item.status)!=-1);
      }

      if(this.date[0]!=null && this.date[1]!=null){
        let date = this.date.map(item=>new Date(item));
        this.tableData=this.tableData.filter(item=>{
          var check = new Date(item.duedate);
          if(check > date[0] && check < date[1]){
            return true;
          }else{
            return false;
          }
        });
      }
      
    }
  }
};
</script>

<style lang="scss">

table {
  border-collapse: separate; /* the default option */
  border-spacing: 0; /* remove border gaps */
}


tr {
   line-height: 10px;
   min-height: 10px;
   height: 10px;
}


.bt {
  border-top: 1px dotted black !important;
}

.bb{
  border-bottom: 1px dotted black !important;
}

td{
  font-size: 9px;
}

.border-padding{
  padding-top: 2px;
  padding-bottom: 2px;
}

@page 
    {
        size: auto;   /* auto is the initial value */
        margin: 0mm;  /* this affects the margin in the printer settings */
    }
  


</style>